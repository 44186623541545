import React,{Component} from 'react'
import {AnimatePresence} from 'framer-motion'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Nav from './components/nav/Nav'
import Landing from './pages/Landing'
import Details from './pages/Details'
import Categories from './pages/Categories'
import AllDeals from './pages/AllDeals'
import axios from 'axios'
import {connect} from 'react-redux'
import { setIcons } from './redux/actions'

import './App.scss'
//details will use id
//article will use vanity url

class App extends Component{
	constructor(props){
		super(props)
		this.state={
			destroyed: false,
			loaded: false,
		}
	}
	componentDidMount(){
		const that = this
		const url = this.props.server +  `/api/icons`
		axios.get(url)
			.then(function (response) {
				if(response.data.success&&!that.state.destroyed){
					//console.log('article,success',url,response.data)
					//TODO: put it in store
					console.log(response.data.result)
					that.props.setIcons({icons: response.data.result})
					that.setState({
						loaded: true
						//data: response.data
					})
				}else{
					console.log('read article error, id:',that.state.id)
				}
			})
			.catch(function (error) {
				// handle error
				console.log(error);
			})
			.then(function () {
				// always executed
			});
	}
	render(){
		return this.state.loaded && (
			<Router>
				<Nav></Nav>
				<Route
					render={({location})=>(
						<AnimatePresence exitBeforeEnter initial={false}>
							<Switch location={location} key={location.pathname}>
								<Route exact path="/" component={Landing}/>
								<Route exact path="/details/:id" component={Details}/>
								<Route exact path="/deals/:id" component={Details}/>
								<Route exact path="/offline/:id" component={Details}/>
								<Route exact path="/categories/:id" component={Categories}/>
								<Route exact path="/deals" component={AllDeals}/>
							</Switch>
						</AnimatePresence>
					)}
				/>
			</Router>
		)
	}
}

const mapStateToProps = state=>({
	server: state.global.server
})

const mapDispatchToProps = {
	setIcons
}

const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(App)

export default AppContainer