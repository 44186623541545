import React,{Component} from 'react'
import {withRouter} from 'react-router-dom'
import {Row,Col} from 'react-bootstrap'
import {connect} from 'react-redux'
import {motion} from 'framer-motion'
import clock from '../../assets/clock.png'
import axios from 'axios'
import moment from 'moment'
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu"

//const icons = [cFood,cTravel,cShopping,cHotel,cApp,cLifestyle,cHighlight]


const swipeConfidenceThreshold = 10000
const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity
}
const itemWidth = 283
const fff = decodeURIComponent

let useSearch = false
let hasResult = false
const result_id = 'result'+Math.random()

const variants = {
    enter: (page) => {
        return {
            x: -itemWidth*page,
            transition: {
                x: { stiffness: 1000, velocity: -100 }
            }
        }
    },
    exit: (page,width)=>{
        return {
            x: -itemWidth*page,
            transition: {
                x: { stiffness: 1000, velocity: -100 }
            }
        }
    },
	reset: ()=>{
		return {
			x: 0,
            transition: {
                x: { stiffness: 1000, velocity: -100 }
            }
		}
	}
}

class Deals extends Component{
	constructor(props){
		super(props)
		this.showDeal = this.showDeal.bind(this)
		this.state = {
			page: 0,
			direction: 0,
			usingSwiper: false,
			loaded:false,
			destroyed: false,
			cxtMenu: 'cm-'+Math.random()+'-'+Math.random(),
			data:[]
		}
		this.onResize = this.onResize.bind(this)
	}
	onResize(){
		if(window.innerWidth>=768){
			if(this.state.usingSwiper){
				this.setState({
					usingSwiper: false,
					page: 0,
					direction: 0
				})
			}
		}else if(window.innerWidth<768){
			if(!this.state.usingSwiper){
				this.setState({
					usingSwiper: true,
					page: 0,
					newDirection: 0,
				})
			}
		}
	}
	componentWillUnmount(){
		this.setState({
			destroyed: true
		})
		window.removeEventListener('resize',this.onResize)
	}
	componentDidMount(){
		const that = this
		if(window.innerWidth<768){
			this.setState({
				usingSwiper: true
			})
		}
		if(this.props.type==='all'){
			useSearch = true
		}
		window.addEventListener('resize',this.onResize)
		const apis={
			popular: this.props.server + this.props.apiDealsPopular,
			latest: this.props.server + this.props.apiDealsLatest,
			related: this.props.useId 
			? this.props.server + this.props.apiDealsRelated + `/${this.props.fromId || ''}`
			: this.props.server + this.props.apiDealsRelated + `Vanity/${this.props.fromId || ''}`,
			all: this.props.server + this.props.apiDealsAll
		}
		const url = apis[this.props.type]
		let max = -1
		if(this.props.type==='related'){
			max = 3
		}
		if(this.props.type==='latest'){
			max = 6
		}
		//console.log('url',url,this.props.type)

		axios.get(url)
			.then(function (response) {
				//console.log(url,response.data)
				if(response.data.success && !that.state.destroyed){
					const array = response.data.result
					that.setState({
						loaded: true,
						data: max===-1 ? array:array.filter((v,k)=>k<max)
					})
				}else{
					console.log('error loading deals', this.props.type,":",response.data.message)
				}
			})
			.catch(function (error) {
				// handle error
				console.log(error);
			})
			.then(function () {
				// always executed
			});
	}
    paginate(newDirection){
		if(!this.state.usingSwiper)return
        const _all = this.state.data.length
        const _new = this.state.page+newDirection
        if(_new<0||_new>_all-1)return
        this.setState(
            {
                page: _new,
                direction: newDirection,
            }
        )
    }
	showDeal(k){
		//console.log('history',this.props.history)
		this.props.history.push(`/details/${k}`)
		window.scrollTo({
			top: 0, 
			behavior: 'smooth'
		})
	}
	showDealFromTitle(k){
		//console.log('history',this.props.history)
		this.props.history.push(`/deals/${k}`)
		window.scrollTo({
			top: 0, 
			behavior: 'smooth'
		})
	}

	renderDealsSliderWrapper(){
		return(
			<Row className="cards cards-slider g-0">
				<motion.div className="more-hint left" 
					animate={{opacity:this.state.page>0?1:0}} initial={{opacity:0}}
					onTap={()=>{this.paginate(-1)}}
				></motion.div>
				<motion.div className="more-hint right" 
					animate={{opacity: this.state.page===2?0:1}} initial={{opacity:0}}
					onTap={()=>{this.paginate(1)}}
				></motion.div>
				<motion.div
					className="scroll-wrapper"
					drag="x"
                    dragConstraints={{ left: 0, right: 0 }}
                    dragElastic={0.5}
                    onDragEnd={(e, { offset, velocity }) => {
                        const swipe = swipePower(offset.x, velocity.x);
                        if (swipe < -swipeConfidenceThreshold || offset.x < -(this.props.itemWidth|283)/2) {
                            this.paginate(1);
                        } else if (swipe > swipeConfidenceThreshold || offset.x > (this.props.itemWidth|283)/2) {
                            this.paginate(-1);
                        }
                    }}
                    transition={{
                        x: { type: "spring", stiffness: 300, damping: 30 },
                        opacity: { duration: 0.2 }
                    }}
				>
					{this.state.loaded && this.renderDealsSlider(this.state.data)}
				</motion.div>
			</Row>
		)
	}

	renderCategory(cat){
		
		let img = ''
		const imgs = this.props.icons.filter(i=>i.id===cat)
		if(imgs.length>0){
			img = imgs[0].icon
		}
		//console.log(this.props.icons)
		return <img className="category" src={img} alt="category" style={{visibility:`${img===''?'hidden':'visible'}`}}></img>
		//return <img className="category" src={img} alt="category"></img>
	}
	renderExpire(arti){
		let dend = moment(new Date(arti.dend))
		let dnow = moment()



		if(arti.show_countdown){
			return (dnow.isAfter(dend)?'Expired ':'Expires ') + moment(dnow).to(dend)
		}else{
			return null
		}
	}
	//卡片内文字
	renderOfferCardInner(val){
		return(
			<ContextMenuTrigger id={this.state.cxtMenu} attributes={{href:val.vanity?`/deals/${val.vanity}`:`/details/${val.id}`,func:val.vanity?val.vanity:val.id,vanity:val.vanity}}>
				<motion.div className="offer-card-inner"
					whileTap={{opacity:0.6}}
					whileHover={{opacity:0.8}}
					onTap={()=>{
						//this.showDeal(val.id)
						//console.log('***********deal clicked',val)
						if(val.vanity){
							this.showDealFromTitle(val.vanity)
						}else{
							this.showDeal(val.id)
						}
					}}
				>
					<img src={val.thumb} width="100%" alt="thumbnail"/>
					{this.props.tag&&<div className="tag">{this.props.tag}</div>}
					{this.renderCategory(val.category)}
					<div className="info">
						<div className="vendor">{fff(val.vendor)}</div>
						<div className="offer-title">{fff(val.title)}</div>
						{this.renderCdNew(val)}
					</div>
				</motion.div>
			</ContextMenuTrigger>
		)
	}
	renderCdNew(val){

		if(val.show_countdown){
			return(
				<div className="expire-date">
					<img src={clock} alt="clock"/>
					<div className="cd">
						{this.renderExpire(val)}
						{/* Offer expires {fff(this.props.article.expire)} */}
					</div>
				</div>
			)
		}
		return null
	}

	handleClick = (e, data, target) => {
		console.log('data',data.action)
		if(data.action==='_blank'){
			console.log('blank')
			const href = target.getAttribute('href')
			window.open(href,'_blank')
		}else{
			console.log('self')
			if(target.getAttribute('vanity')){
				this.showDealFromTitle(target.getAttribute('func'))
			}else{
				this.showDeal(target.getAttribute('func'))
			}
		}
    }

	renderDealsWrapper(){
		hasResult = false
		setTimeout(()=>{ 
			let tar = document.getElementById(result_id)
			if(tar){
				if(useSearch&&!hasResult){
					tar.innerHTML='No search result'
				}else{
					tar.innerHTML=''
				}
			}
		}, 500)
		setTimeout(()=>{console.log('render result:',useSearch&&!hasResult)},1000)
		return(
			<Row className="cards g-0">
				{this.state.loaded && this.renderDeals(this.state.data)}
				<div id={result_id}></div>
			</Row>
		)
	}
	getDisplay(v,pre,re){
		if(!this.props.type==='all') return true
		let wMatch = pre
		if(!pre){
			wMatch = re.test(fff(v.vendor+v.title+v.category+v.vanity).replaceAll(' ',''))
		}
		if(!this.props.useFilter){
			if(wMatch)hasResult=true
			return wMatch
		} 
		if(!this.props.filters[this.props.icons.map(i=>i.id).indexOf(v.category)]) return false
		if(wMatch){
			hasResult = true
		}
		return wMatch
	}

	renderDeals(data){
		const pre = this.props.words===''
		const re=this.props.words ? new RegExp(this.props.words.replaceAll(' ','[^\s]?'),'ig') : new RegExp('')
		return data.map((v,k)=>{
			return (
				<Col className="offer-card g-0" xs="6" md="4" key={k} style={{
					display: this.getDisplay(v,pre,re)?'flex':'none'
				}}>
					{this.renderOfferCardInner(v)}
				</Col>
			)
		})
	}
	renderDealsSlider(data){
		return data.map((v,k)=>{
			return (
				<motion.div className="offer-card-slider" key={k}
					custom={this.state.page}
					variants={variants}
					initial="reset"
					animate={this.state.usingSwiper ? (k === this.state.page ? 'enter' : 'exit'):'reset'}
				>
					{this.renderOfferCardInner(v)}
				</motion.div>
			)
		})
	}

	render(){
		return (
			<div className="container deals-wrapper g-0">
				<Row className="title-row g-0" style={{visibility: this.props.title===""?'hidden':'visible'}}>
					<h2 className="title">{this.props.title}</h2>
				</Row>
				{this.props.needSlider?this.renderDealsSliderWrapper():this.renderDealsWrapper()}
				<ContextMenu id={this.state.cxtMenu}>
					<MenuItem data={{action: '_blank'}} onClick={this.handleClick}>
						Open deal in new tab
					</MenuItem>
					<MenuItem data={{action: '_parent'}} onClick={this.handleClick}>
						Open deal in current tab
					</MenuItem>
				</ContextMenu>
			</div>
		)
	}
}

const mapStateToProps = state=>({
	isMobile: state.global.isMobile || false,
	server: state.global.server,
	apiDealsAll: state.global.apiDealsAll,
	apiDealsPopular: state.global.apiDealsPopular,
	apiDealsLatest: state.global.apiDealsLatest,
	apiDealsRelated: state.global.apiDealsRelated,
	icons:state.page.icons
})

const mapDispatchToProps = {
}

const DealsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Deals)

export default withRouter(DealsContainer)

