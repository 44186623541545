export const pageVariants = {
    enter: {
        opacity: 1,
        transition: {
            x: { stiffness: 1000, velocity: -100 }
        }
    },
    exit: {
        opacity: 0,
        transition: {
            x: { stiffness: 1000 }
        }
    }
}