function Location() {};

Location.prototype.getLocation = function(callback,errCallback) {
	var options = {
		enableHighAccuracy: true,
		maximumAge: 1000
	};
	this.callback = Object.prototype.toString.call(callback) === "[object Function]" ?
		callback :
		function(address) {
			alert(address.province + address.city);
			console.log("getocation(callbackFunction) 可获得定位信息对象");
		};
	var self = this;
	if (navigator.geolocation) {
		//浏览器支持geolocation
		console.log('browser support geolocation')
		navigator.geolocation.getCurrentPosition(function(position) {
			//经度
			var longitude = position.coords.longitude;
			//纬度
			var latitude = position.coords.latitude;
			console.log('browser get geolocation',longitude,latitude)
			self.callback(position.coords)
		}, errCallback, options);
	} else {
		//浏览器不支持geolocation
		console.log('browser does not support geolocation')
	}
};


export default Location
