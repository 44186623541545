import React, { useEffect, useRef, useCallback, useState } from "react";
import { useDispatch} from "react-redux"
import { PIN_SELECTED } from "../../redux/types";

export default function BingMapsReact({
  bingMapsKey,
  height,
  mapOptions,
  onMapReady,
  pushPins,
  pushPinsWithInfoboxes,
  viewOptions,
  width,
}) {
  // refs
  const mapContainer = useRef(null);
  const map = useRef(null);
  const dispatch = useDispatch()
  const [ibox, setIbox] = useState(null)

  // removes pushpins
  function removePushpins(map, Maps) {
    for (var i = map.entities.getLength() - 1; i >= 0; i--) {
      var pushpin = map.entities.get(i);
      if (pushpin instanceof Maps.Pushpin) {
        map.entities.removeAt(i);
      }
    }
  }

  // add pushpins with infoboxes
  const addPushpinsWithInfoboxes = useCallback(
    (pushPinsToAdd, 
		infobox, 
		map, 
		Maps) => {
      removePushpins(map, Maps);
      pushPinsToAdd.forEach((pushPin) => {
        if (pushPin === null) {
          return;
        }
		//console.log(Maps.Point, Maps.Pushpin)
        const newPin = new Maps.Pushpin(pushPin.center, pushPin.options);
        newPin.metadata = pushPin.metadata;

        Maps.Events.addHandler(newPin, "click", (e) => {
			console.log(newPin.metadata.title)
			dispatch({type: PIN_SELECTED,payload:{pinSelected:newPin.metadata.pinId,deal:newPin.metadata.deal}})
			infobox.setOptions({
				location: e.target.getLocation(),
				title: e.target.metadata.title,
				visible: true
			});
		})
        map.entities.push(newPin);
      });
    },
    []
  );

  // add pushpins
  const addPushpins = useCallback((pushPinsToAdd, map, Maps) => {
    removePushpins(map, Maps);
    pushPinsToAdd.forEach((pushPin) => {
      if (pushPin === null) {
        return;
      }
      const newPin = new Maps.Pushpin(pushPin.center, pushPin.options);
      map.entities.push(newPin);
    });
  },[])

  // set view options
  function setMapViewOptions(map, viewOptions, Maps) {
    const options = { ...viewOptions };
    if (viewOptions.mapTypeId) {
      options.mapTypeId = Maps.MapTypeId[viewOptions.mapTypeId];
    }
    if (viewOptions.hideRoadLabels) {
      options.labelOverlay = Maps.LabelOverlay.hidden;
    }
	try{
		map.setView(options);
	}catch(e){
		console.log('map error',e)
	}
  }

  // set map options
  function setMapOptions(map, mapOptions, Maps) {
    const options = { ...mapOptions };

    // some map options require values from the Maps class
    // these conditional statements handle those cases
    if (mapOptions.navigationBarMode) {
      options.navigationBarMode =
        Maps.NavigationBarMode[mapOptions.navigationBarMode];
    }
    if (mapOptions.navigationBarOrientation) {
      options.navigationBarOrientation =
        Maps.NavigationBarOrientation[mapOptions.navigationBarOrientation];
    }
    if (mapOptions.supportedMapTypes) {
      options.supportedMapTypes = mapOptions.supportedMapTypes.map(
        (type) => Maps.MapTypeId[type]
      );
    }
    map.setOptions(options);
  }

  // make map, set options, add pins
  const makeMap = useCallback(() => {
    const { Maps } = window.Microsoft;

    // only make a new map if one doesn't already exist
    if (!map.current) {
      map.current = new Maps.Map(mapContainer.current, {
        credentials: bingMapsKey,
      });
    }
    // set viewOptions, if any
    if (viewOptions) {
      setMapViewOptions(map.current, viewOptions, Maps);
    }

    // set mapOptions, if any
    if (mapOptions) {
      setMapOptions(map.current, mapOptions, Maps);
    }

    // add push pins, if any
    if (pushPins) {
      addPushpins(pushPins, map.current, Maps);
    }

    // add infoboxes, if any
    if (pushPinsWithInfoboxes) {
		let _b = ibox
		if(_b===null){
			_b = new Maps.Infobox(map.current.getCenter(), {
				visible: false,
				// title: 'You are here'
			})
			_b.setMap(map.current);
		}else{
			_b = ibox
		}
		if(ibox===null){
			setIbox(_b)
		}
		_b.setOptions({
			location: map.current.center,
			visible: true
		});
		addPushpinsWithInfoboxes(
			pushPinsWithInfoboxes,
			_b,
			map.current,
			Maps
		);
    }
    onMapReady && onMapReady({ map });
  }, [
    addPushpinsWithInfoboxes,
    addPushpins,
    bingMapsKey,
    mapOptions,
    onMapReady,
    pushPins,
    pushPinsWithInfoboxes,
    viewOptions,
  ]);

  useEffect(() => {
    if (window.Microsoft && window.Microsoft.Maps) {
      makeMap();
    } else {
      const scriptTag = document.createElement("script");
      scriptTag.setAttribute("type", "text/javascript");
      scriptTag.setAttribute(
        "src",
        `https://www.bing.com/api/maps/mapcontrol?callback=makeMap`
      );
      scriptTag.async = true;
      scriptTag.defer = true;
      document.body.appendChild(scriptTag);
      window.makeMap = makeMap;
    }
  }, [makeMap]);

  return (
    <div className="myBingMap" ref={mapContainer}></div>
  );
}




BingMapsReact.defaultProps = {
  bingMapsKey: null,
  mapOptions: null,
  onMapReady: null,
  pushPins: null,
  pushPinsWithInfoboxes: null,
  viewOptions: null,
};