import React,{Component} from "react"
import {connect} from 'react-redux'
import {Container, Row,Col} from 'react-bootstrap'
import GoogleMapReact from 'google-map-react'
import loc_c from '../../assets/loc_current2.png'
import loc_o from '../../assets/loc_other2.png'
import { selectPin } from "../../redux/actions";
import DealForGMap from "./DealForGMap"


class LocationPin extends Component{
	constructor(props){
		super(props)
	}
	render(){
		return(
			<div className="pin">
				<img className="pin-icon" src={this.props.icon}
					onClick={()=>{this.props.click()}}
				/>
				{this.props.selected && <p className="pin-text">{this.props.metadata.title}</p>}
			</div>
		)
	}
}



class GMapInner extends Component{
	constructor(props){
		super(props)
		console.log('gamp inner inited')
		this.state={
			pushPins:[],
			g_m_key: 'AIzaSyC0DKwOxv3Z5gEdBMzeMSbkG3J9HyJ1bCU',
			pinsLoaded: false,
			h: '400px'
		}
	}
	componentDidMount(){
		this.addPushPins = this.addPushPins.bind(this)
		this.addPushPins()
	}
	addPushPins(){
		const url = this.props.server + this.props.apiDealsAll
		fetch(url)
			.then((res) => res.json())
			.then((jsonRes) => {
				console.log('-------------',jsonRes)
				let newPins = jsonRes.result.filter(r=>r.longitude!==null&&r.latitude!==null&&r.longitude!==0&&r.latitude!==0).map(
					(resource,i) => {
						//console.log('resource',resource)
						return {
							center: {
								latitude: resource.latitude,
								longitude: resource.longitude,
							},
							options: {
								icon: loc_o,
								showCloseButton: false,
							},
							metadata:{
								title: unescape(resource.vendor),
								deal: resource,
								pinId: i
							}
						};
					}
				);
				newPins.push({
					center: this.props.geo,
					options: {
						icon: loc_c,
						showCloseButton: false,
					},
					metadata:{
						title: 'You are here',
						deal: null,
						pinId: -1
					}
				})

				this.setState({
					pushPins: newPins,
					pinsLoaded: true
				})
				console.log(newPins)
				//setPushPins(newPins);
			});
	}
	createMapOptions(){
		return {
			panControl: false,
			mapTypeControl: false,
			scrollwheel: false,
			styles: [{ stylers: [{ 'saturation': -100 }, { 'gamma': 0.8 }, { 'lightness': 4 }, { 'visibility': 'on' }] }]
		  }
	}
	render(){
		if(!this.state.pinsLoaded){
			return(
				<Container fluid className="mapContainer g-0">
					<div className="container deals-wrapper g-0">
						<Row className="title-row g-0">
							<h2 className="title">Deals near you</h2>
						</Row>
						<Row className="title-row g-0">
							<Col className="text-center">
								Loading...
							</Col>
						</Row>
					</div>
				</Container>
			)
		}
		return (
			<Container fluid className="mapContainer g-0">
			<div className="container deals-wrapper g-0">
				<Row className="title-row g-0">
					<h2 className="title">Deals near you</h2>
				</Row>
			</div>
			{/* <div className="mapWrapper" style={{height: this.state.h}}> */}
			<div className="mapWrapper">
				<div className="myBingMap">
					<GoogleMapReact
						bootstrapURLKeys={{ key: this.state.g_m_key}}
						defaultCenter={{lat: this.props.geo.latitude, lng:this.props.geo.longitude}}
						defaultZoom={14}
						options={this.createMapOptions}
					>
						{this.state.pushPins.map((l,i)=>{
							return(
								<LocationPin
									key={`lp-${i}`}
									lat={l.center.latitude}
									lng={l.center.longitude}
									//path={`/static/vendors/${l.address.replaceAll(' ','')}/${i}.png`}
									//text={`${l.address}`}
									metadata={l.metadata}
									icon={l.options.icon}
									selected = {l.metadata.pinId === this.props.pinSelected}
									click={
										()=>{
											this.props.selectPin({
												pinSelected:l.metadata.pinId,deal:l.metadata.deal
											})
										}
									}
								/>
							)
						})}
					</GoogleMapReact>
				</div>
				<DealForGMap/>
				

				{/* <BingMapsReact
					onMapReady={({ map }) => {
						setMapReady(true);
					}}
					bingMapsKey={'AjAgn283svwO0-jpaEYzsR8L7nfihCIvks9W4ycqkBztF8foxPeCnTtxcER6xpsy'}
					pushPinsWithInfoboxes={pushPins}
					mapOptions={{
						navigationBarMode: "compact",
						disableBirdseye: true,
						disableScrollWheelZoom: true,
						enableClickableLogo: false,
						navigationBarOrientation: 'horizontal',
						showMapTypeSelector: false,
						showTermsLink: false,
						enableHighDpi: true,
					}}
					viewOptions={{
						center: geo,
						mapTypeId: "grayscale",
						zoom: 14,
					}}
				/>
				<DealForMap/> */}
			</div>
			</Container>
		)
	}
}


const mapStateToProps = state=>({
	server: state.global.server,
	pinSelected: state.page.pinSelected,
	apiDealsAll: state.global.apiDealsAll
})

const mapDispatchToProps = {
	selectPin
}

const GMapInnerContainer = connect(mapStateToProps,mapDispatchToProps)(GMapInner)

export default GMapInnerContainer