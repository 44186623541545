import React, { useState, useEffect } from "react"
import BingMapsReact from "./BingMapsReact"
import {Container, Row} from 'react-bootstrap'
import { useSelector} from "react-redux"
import DealForMap from './DealForMap'

// import loc_c from '../../assets/loc_current.svg'
// import loc_o from '../../assets/loc_other.svg'
import loc_c from '../../assets/loc_current.png'
import loc_o from '../../assets/loc_other.png'





function MapInner({geo}) {
	const [pushPins, setPushPins] = useState([])
	const [mapReady, setMapReady] = useState(false)
	const server = useSelector(state => state.global.server)
	const apiDealsAll = useSelector(state=>state.global.apiDealsAll)

	function addPushPin() {
		const url = server + apiDealsAll
		fetch(url)
			.then((res) => res.json())
			.then((jsonRes) => {
				console.log('-------------')
				let newPins = jsonRes.result.filter(r=>r.longitude!==null&&r.latitude!==null).map(
					(resource,i) => {
						//console.log('resource',resource)
						return {
							center: {
								latitude: resource.latitude,
								longitude: resource.longitude,
							},
							options: {
								icon: loc_o,
								showCloseButton: false,
							},
							metadata:{
								title: unescape(resource.vendor),
								deal: resource,
								pinId: i
							}
						};
					}
				);
				newPins.push({
					center: geo,
					options: {
						icon: loc_c,
						showCloseButton: false,
					},
					metadata:{
						title: 'You are here',
						deal: null,
						pinId: -1
					}
				})
				console.log(newPins)
				setPushPins(newPins);
			});
	}

	useEffect(() => {
		if (mapReady) {
			addPushPin();
		}
	}, [mapReady]);

	return (
		<Container fluid className="mapContainer g-0">
			<div className="container deals-wrapper g-0">
				<Row className="title-row g-0">
					<h2 className="title">Deals near you</h2>
				</Row>
			</div>
			<div className="mapWrapper">
				<BingMapsReact
					onMapReady={({ map }) => {
						setMapReady(true);
					}}
					bingMapsKey={'AjAgn283svwO0-jpaEYzsR8L7nfihCIvks9W4ycqkBztF8foxPeCnTtxcER6xpsy'}
					pushPinsWithInfoboxes={pushPins}
					mapOptions={{
						navigationBarMode: "compact",
						disableBirdseye: true,
						disableScrollWheelZoom: true,
						enableClickableLogo: false,
						navigationBarOrientation: 'horizontal',
						showMapTypeSelector: false,
						showTermsLink: false,
						enableHighDpi: true,
					}}
					viewOptions={{
						center: geo,
						mapTypeId: "grayscale",
						zoom: 14,
					}}
				/>
				<DealForMap/>
			</div>
		</Container>
	)
}

export default MapInner;