import React,{Component} from 'react'
import {Container, Row,Col} from 'react-bootstrap'
import Location from '../../utils/Location'
import MapInner from './MapInner'
import GMapInner from './GMapInner'


class MapOuter extends Component{
	constructor(props){
		super(props)
		this.state={
			permitted: false,
			geo: null,
			destroyed: false,
		}
	}
	componentDidMount(){
		let geo = new Location()
		this.onGetLoc = this.onGetLoc.bind(this)
		this.onGetLocFail = this.onGetLocFail.bind(this)
		geo.getLocation(this.onGetLoc,this.onGetLocFail)
	}
	componentWillUnmount(){
		this.setState({
			destroyed: true
		})
	}
	onGetLoc(res){
		if(this.state.destroyed)return
		this.setState({
			geo: {latitude:res.latitude,longitude:res.longitude},
			permitted: true,
		})
	}
	onGetLocFail(error){
		switch (error.code) {
			case 1:
				console.log('rejected')
				break;
			case 2:
				console.log('can not get geolocation')
				break;
			case 3:
				console.log('get geolocation overtime')
				break;
			case 4:
				console.log('get geolocation undefined error')
				break;
		}
	}
	render(){
		if(!this.state.permitted){
			return <Container fluid className="mapContainer g-0">
			<div className="container deals-wrapper g-0">
				<Row className="title-row g-0">
					<h2 className="title">Deals near you</h2>
				</Row>
				<Row>
					<Col style={{textAlign:"center"}}>
						Turn on location service to enjoy this feature 
					</Col>
				</Row>
			</div>
		</Container>
		}
		// return(
		// 	<MapInner geo={this.state.geo}/>
		// )
		return(
			<GMapInner geo={this.state.geo}/>
		)
	}
}

export default MapOuter
