import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import {isIOS, isAndroid} from 'react-device-detect'
import {Provider} from 'react-redux'
import configureStore from './redux/store'
import axios from 'axios'




const version = '0.9.6_v3'
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

console.log(`%c********VERSION:************:%c${version}`,'color:green','color:red')
console.log(window.location.hostname)

const hostNames = [
	'http://localhost:3333',
	'https://preprod.amazebyinstarem.com'
]
const useUat = true
const testHost = useUat ?hostNames[1]:hostNames[0]


function init(settings) {
	//console.log('is:',isIOS,isAndroid,isDesktop)
	ReactDOM.render(
		<Provider store={configureStore(
			{
				isMobile: isAndroid || isIOS,
				root: settings.root,
				server: window.location.hostname==='www.amazebyinstarem.com'?settings.api_server:window.location.hostname==='localhost'?testHost:'https://preprod.amazebyinstarem.com',
				//server: 'https://preprod.amazebyinstarem.com',
				apiCarousel: settings.api_carousel,
				apiDealsAll: settings.api_deals_all,
				apiDealsPopular: settings.api_deals_popular,
				apiDealsLatest: settings.api_deals_latest,
				apiDealsRelated: settings.api_deals_related,
				apiArticle: settings.api_article,
				snsFacebook: settings.sns_share_facebook,
				snsTwitter: settings.sns_share_twitter,
				snsTelegram: settings.sns_share_telegram,
				snsLinkedin: settings.sns_share_linkedin,
				whys: settings.why_use_amaze,
				aDownload: isIOS?settings.app_download_ios:isAndroid?settings.app_download_android:settings.app_download_pc
			}
		)}>
			<App />
		</Provider>,
		document.getElementById('root')
	)
}


axios.get('/settings.json')
	.then(function (response) {
		init(response.data)
	})
	.catch(function (error) {
		// handle error
		console.log(error);
	})
	.then(function () {
		// always executed
	});

