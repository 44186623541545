import React,{useEffect, useState} from "react"
// import cFood from '../../assets/category_food.svg'
// import cTravel from '../../assets/category_travel.svg'
// import cShopping from '../../assets/category_shopping.svg'
// import cHotel from '../../assets/category_hotel.svg'
// import cApp from '../../assets/category_app.svg'
// import cLifestyle from '../../assets/category_lifestyle.svg'
// import cHighlight from '../../assets/category_highlight.svg'
import parse from 'html-react-parser'
import {motion} from 'framer-motion'
import { useSelector} from "react-redux"
import axios from "axios"
import { Component } from "react"
import {connect} from 'react-redux'
//const icons = [cFood,cTravel,cShopping,cHotel,cApp,cLifestyle,cHighlight]
const icons = []

const fff = unescape
const trimStart = str => str.replace(new RegExp('^([\\s]*)(.*)$'), '$2')
function ggg(str){
	//console.log('str:',str)
	if(str===null)return ''
	return str.split('\\n').map((v,i)=>{
		let t_str = trimStart(v)
		const hasIndent = t_str.indexOf('&emsp;')>=0
		return <div style={{marginBottom:'20px',marginLeft:hasIndent?'30px':'0px'}} key={`ggg-${i}`}>{t_str.replace('&emsp;','')}</div>
	})
}



class DealForGMap extends Component{
	constructor(props){
		super(props)
		this.state = {
		}
	}

	renderDHT(article,index){
		if(!article)return null
		console.log('article',article)
		const keyName = ['details','howtos'][index]
		const compareKeyName = ['details_new','howtos_new'][index]
		const classPre = ['offer','howto'][index]
		const keyPre = ['d','h'][index]

		if(article[compareKeyName]===null || fff(article[compareKeyName])==='<div>null</div>'||article[compareKeyName]===undefined){
			return (JSON.parse(fff(article[keyName])).filter(f=>f!=='').map((v,k)=>{
				return(
						<div
							className={`article-${classPre}-item p`} key={`${keyPre}-${k}`}
						>
							{ggg(v)}
						</div>
				)
			}))
		}else{
			return (
				<div 
					className={`${compareKeyName}-inner`}
				>
					{parse(fff(article[compareKeyName]))}
				</div>
			)
		}

	}
	render(){
		return(
					<motion.div className="dealInfo" style={{display: this.props.pinSelected===-1?'none':'block'}}>
						{this.props.dealSelected&&(
							<div className="offer-card-inner">
								<img src={this.props.dealSelected.thumb} width="100%" alt="thumbnail"/>
								{this.props.dealSelected.category>0 && <img className="category" src={this.props.icons.filter(i=>i.id===this.props.dealSelected.category)[0].icon} alt="category"></img>}
								<div className="info">
									<div className="vendor">{unescape(this.props.dealSelected.vendor)}</div>
									<div className="offer-title">{unescape(this.props.dealSelected.title)}</div>
								</div>
								<div className="article-offer-details">
									<div className="article-offer-details-title"><b>Offer Details</b></div>
									<div className="article-offer-details-content">
										{this.renderDHT(this.props.dealSelected,0)}
									</div>
								</div>
								<div className="article-howto">
									<div className="article-howto-title"><b>How to redeem</b></div>
									<div className="article-howto-content">
										{this.renderDHT(this.dealSelected,1)}
									</div>
								</div>


								<div className="btn-wrapper">
									<motion.a className="btn-see-deal" 
										whileHover={{opacity:0.6,backgroundColor:'#2b2b2b',color:'white'}}
										href={this.props.dealSelected.vanity?`/deals/${this.props.dealSelected.vanity}`:`/details/${this.props.dealSelected.id}`}
									>
										See deal
									</motion.a>
								</div>
							</div>
						)}
					</motion.div>
		)
	}
}

const mapStateToProps = state=>({
	pinSelected: state.page.pinSelected,
	dealSelected: state.page.deal,
	icons: state.page.icons
})

const mapDispatchToProps = {
}

const DealForGMapContainer = connect(mapStateToProps,mapDispatchToProps)(DealForGMap)


export default DealForGMapContainer