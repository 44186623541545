import React,{Component} from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import Article from '../components/article/Article'
import Deals from '../components/deals/Deals'
import {motion} from 'framer-motion'
import {pageVariants} from '../utils/Animate'
import Promotion from '../components/promotion/Promotion'
import Footer from '../components/footer/Footer'
import axios from 'axios'

class Details extends Component{
	constructor(props){
		super(props)
		this.state={
			id: this.props.match.params.id,
			destroyed: false,
			useId: window.location.href.indexOf('/deals/')===-1,
			offline: window.location.href.indexOf('/offline/')!==-1,
			data: null,
		}
	}
	componentWillUnmount(){
		this.setState({
			destroyed:true
		})
	}
	componentDidMount(){
		//console.log('***********useID',this.state.useId,window.location.href)
		const that = this
		//const url = this.props.server + this.props.apiArticle + `/${this.state.id.replaceAll('_','%20')}`
		let url = (this.state.useId || this.state.offline)
		? this.props.server + this.props.apiArticle + `/${this.state.id}`
		: this.props.server + this.props.apiArticle + `Vanity/${this.state.id}`
		axios.get(url)
			.then(function (response) {
				if(response.data.success&&!that.state.destroyed){
					//console.log('article,success',url,response.data.result)
					if(response.data.result.length>0){
						that.setState({
							data: response.data.result[0]
						})
					}
				}else{
					console.log('read article error, id:',that.state.id)
				}
			})
			.catch(function (error) {
				// handle error
				console.log(error);
			})
			.then(function () {
				// always executed
			});
	}
	render(){
		return (
			<motion.div
				variants={pageVariants}
				initial="exit"
				animate="enter"
				exit="exit"
				className="details-page"
			>
				<div className="section">
					{this.state.data && <Article article={this.state.data} offline={this.state.offline}/>}
				</div>

				{/* {!this.state.offline && <Deals title="Related Deals" type="related" fromId={this.state.id} needSlider={true} useId={this.state.useId}/>}
				{!this.state.offline && <Promotion/>}
				{!this.state.offline && <Footer/>} */}
				<Deals title="Related Deals" type="related" fromId={this.state.id} needSlider={true} useId={this.state.useId}/>
				<Promotion/>
				<Footer/>
			</motion.div>
		)
	}
}

const mapStateToProps = state=>({
	server: state.global.server,
	apiArticle: state.global.apiArticle,
})

const mapDispatchToProps = {
}

const DetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Details)

export default withRouter(DetailsContainer)