import {
	PIN_SELECTED,
    SEARCHING,
	SET_ICONS,
} from '../types'

const initialState = {
	searching: false,
	pinSelected: -1,
	icons:[],
	deal: null
}

const reducer = (state=initialState,action)=>{
    switch(action.type){
        case SEARCHING:
            return {
                ...state,
				searching: action.payload
            }
		case PIN_SELECTED:
			console.log('pin selected',action.payload)
			return {
				...state,
				pinSelected: action.payload.pinSelected,
				deal: action.payload.deal
			}
		case SET_ICONS:
			return{
				...state,
				icons: action.payload.icons
			}
        default:
            return state
    }
}

export default reducer