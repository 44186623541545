import React,{Component} from 'react'
import {motion} from 'framer-motion'
import {Container} from 'react-bootstrap'
import clock from '../../assets/clock.png'
import Facebook from '../../assets/Facebook.svg'
import Telegram from '../../assets/Telegram.svg'
import Linkedin from '../../assets/Linkedin.svg'
import Twitter from '../../assets/Twitter.svg'
import tnc from '../../assets/tnc.svg'
import tnc2 from '../../assets/tnc2.svg'
import {AnimatePresence} from 'framer-motion'
import {pageVariants} from '../../utils/Animate'
import {connect} from 'react-redux'
import parse from 'html-react-parser'
import moment from 'moment'


const fff=unescape || decodeURI
const trimStart = str => str.replace(new RegExp('^([\\s]*)(.*)$'), '$2')
const tick_id = 'tick' + Math.random()



class Article extends Component{
	constructor(props){
		super(props)
		this.state ={
			destroyed: false,
			tncShow:false||this.props.offline,
			tick: props.article.show_countdown,
			exp: ''
		}
	}
	componentDidMount(){
		this.tick = this.tick.bind(this)
		if(!this.state.destroyed){
			setTimeout(()=>{
				this.tick()
			},1000)
		}
	}
	tick(){
		if(this.state.destroyed)return


// 		var now = moment(); // new Date().getTime();
// var then = moment().add(60, 'seconds'); // new Date(now + 60 * 1000);

// $(".now").text(moment(now).format('h:mm:ss a'));
// $(".then").text(moment(then).format('h:mm:ss a'));
// $(".duration").text(moment(now).to(then));
// (function timerLoop() {
//   $(".difference > span").text(moment().to(then));
//   $(".countdown").text(countdown(then).toString());
//   requestAnimationFrame(timerLoop);
// })();

		let dstart = moment(new Date(this.props.article.dstart))
		let dend = moment(new Date(this.props.article.dend))
		let dnow = moment()
		


		// if(document.getElementById(tick_id)){
		// 	document.getElementById(tick_id).innerHTML = moment(dnow).to(dstart)
		// }

		this.setState({
			exp: (dnow.isAfter(dend)?'Expired ':'Expires ') + moment(dnow).to(dend)
		})



	// 	let d1 = dataset.dstart?new Date(dataset.dstart.replaceAll('"','')):new Date()
	// let d2 = dataset.dend?new Date(dataset.dend.replaceAll('"','')):new Date()
	// $('#ids_p1').val(moment(d1).format('YYYY-MM-DD HH:mm'))
	// $('#ids_p2').val(moment(d2).format('YYYY-MM-DD HH:mm'))


		console.log(dstart,dend,dnow,moment(dnow).to(dstart))

		setTimeout(()=>{
			this.tick()
		},1000)
	}
	componentWillUnmount(){
		this.setState({
			destroyed:true
		})

	}
	// componentDidMount(){
	// 	//console.log('article',this.props.article)
	// }
	ggg(str){
		//console.log('str:',str)
		if(str===null)return ''
		return str.split('\\n').map((v,i)=>{
			let t_str = trimStart(v)
			const hasIndent = t_str.indexOf('&emsp;')>=0
			return <div style={{marginBottom:'20px',marginLeft:hasIndent?'30px':'0px'}} key={`ggg-${i}`}>{t_str.replace('&emsp;','')}</div>
		})
	}
	renderExpire(arti){
		if(arti.show_countdown){
			return this.state.exp
		}else{
			return null
		}
	}
	renderCta(key){
		return(
			<div>
				<motion.div 
					className="shop-now" whileHover={{opacity:0.8}} whileTap={{opacity:0.6}}
					onTap={()=>{
						window.open(fff(this.props.article.link), '_blank').focus();
					}}
					key={`cta-${key}`}
				>
					Shop now
				</motion.div>
				<div className="expire-date">
					<img src={clock} alt="clock"/>
					<div id={tick_id} className="cd-wrapper">
						<div>Offer expires {this.props.article.expire!=='null'&&this.props.article.expire!==''?fff(this.props.article.expire).replace('null',''):moment(this.props.article.dend).format('ll')}</div> 
						<br/>
						<div className="cd">{this.renderExpire(this.props.article)}</div>
						{/* Offer expires {fff(this.props.article.expire)} */}
					</div>
				</div>
			</div>
		)
	}
	sns(type){
		switch(type){
			case 'Facebook':
				return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(document.URL+'&description=')+this.props.article.title+encodeURIComponent('&picture='+this.props.article.thumb)}`
			case 'Twitter':
				return `https://twitter.com/intent/tweet?source=${encodeURIComponent(document.URL)}&text=${this.props.article.title}&via=amaze`
			case 'Telegram':
				return `https://telegram.me/share/url?url=${encodeURIComponent(document.URL)}&text=${this.props.article.title}`
			case 'Linkedin':
				return `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(document.URL)}&title=${this.props.article.title}&summary=${encodeURIComponent('Shop now!')}&source=${this.props.root}`
			default:
				return '#'
		}
	}
	renderShare(key){
		return(
			<div className="sns-share" key={`share-wrapper-${key}`}>
				<div className="sns-share-title">Share with friend</div>
				<div className="sns-share-btns">
					<a href={this.sns('Facebook')} target="_blank" rel="noreferrer"><img src={Facebook} alt="facebook"/></a>
					<a href={this.sns('Twitter')} target="_blank" rel="noreferrer"><img src={Twitter} alt="twitter"/></a>
					<a href={this.sns('Telegram')} target="_blank" rel="noreferrer"><img src={Telegram} alt="telegram"/></a>
					<a href={this.sns('Linkedin')} target="_blank" rel="noreferrer"><img src={Linkedin} alt="linkedin"/></a>
				</div>
			</div>
		)
	}

	// details_new: 'TEXT',
	// 		howtos_new: 'TEXT',
	// 		tncs_new: 'TEXT',
	renderDHT(article,index){
		const keyName = ['details','howtos','tncs'][index]
		const compareKeyName = ['details_new','howtos_new','tncs_new'][index]
		const classPre = ['offer','howto','tnc'][index]
		const keyPre = ['d','h','t'][index]

		const toShow = (this.state.tncShow && index===2 ) || index!==2 || this.props.offline

		// console.log('-----------')
		// console.log('old:',article[keyName])
		// console.log('new:',article[compareKeyName])

		if(article[compareKeyName]===null || fff(article[compareKeyName])==='<div>null</div>'){
			return (toShow && JSON.parse(fff(article[keyName])).filter(f=>f!=='').map((v,k)=>{
				return(
						<motion.div
							className={`article-${classPre}-item p`} key={`${keyPre}-${k}`}
							variants={pageVariants}
							initial={index===2&&!this.props.offline?'exit':'enter'}
							animate="enter"
							exit="exit"
						>
							{this.ggg(v)}
						</motion.div>
				)
			}))
		}else{
			return (
				toShow && <motion.div 
					className={`${compareKeyName}-inner`}
					variants={pageVariants}
					initial={index===2&&!this.props.offline?'exit':'enter'}
					animate="enter"
					exit="exit"
				>
					{parse(fff(article[compareKeyName]))}
				</motion.div>
			)
		}

	}

	render(){
		const article = this.props.article
		return (
			<Container fluid className="article-wrapper g-0">
				<Container fluid className="header-wrapper g-0">
					<img src={article.image} width="100%" alt="header"/>
				</Container>
				<Container className="content g-0">
					<div className="inner">
					<div className="article-vendor">{fff(article.vendor)}</div>
					<div className="article-body">
						<div className="article">
							<div className="article-title">{fff(article.title)}</div>
							<div className="article-offer-details">
								<div className="article-offer-details-title">Offer Details</div>
								<div className="article-offer-details-content">
									{/* {this.renderDetails(article)} */}
									{this.renderDHT(article,0)}
								</div>
							</div>
							<motion.div className="article-cta-inline article-cta d-block d-md-none"
								style={{visibility:this.props.offline?'hidden':'visible'}}
							>
								{this.renderCta(0)}
							</motion.div>
							<div className="article-howto">
								<div className="article-howto-title">How to redeem</div>
								<div className="article-howto-content">
									{/* {this.renderHowtos(article)} */}
									{this.renderDHT(article,1)}
								</div>
							</div>
							<div className="article-tnc">
								<motion.div className="article-tnc-title"
									whileHover={{opacity:0.8}}
									whileTap={{opacity:0.6}}
									onTap={()=>{
										if(!this.props.offline){
											this.setState({
												tncShow: !this.state.tncShow
											})
										}
									}}
								>Terms & conditions <span style={{display:this.props.offline?'none':'inline-block'}}><img src={this.state.tncShow ? tnc2:tnc} alt="expand" width="24" style={{marginTop:'-5px',marginLeft:'12px'}}/></span></motion.div>
								<div className="article-tnc-content">
									<AnimatePresence exitBeforeEnter initial={false}>
										<div>
											{/* {this.renderTncs(article)} */}
											{this.renderDHT(article,2)}
										</div>
									</AnimatePresence>

								</div>
							</div>
							<div className="share-wrapper d-block d-md-none"
								style={{visibility:this.props.offline?'hidden':'visible'}}
							>
								{this.renderShare(0)}
							</div>
						</div>
						<div className="article-cta-float article-cta d-none d-md-block"
							style={{visibility:this.props.offline?'hidden':'visible'}}
						>
							{this.renderCta(1)}
							{this.renderShare(1)}
						</div>
					</div>
					{/* <img src={tmpAssets.article1} width="100%" className="d-none d-md-block"/>
					<img src={tmpAssets.article2} width="100%" className="d-block d-md-none"/> */}
					</div>
				</Container>
			</Container>
		)
	}
}

const mapStateToProps = state=>({
	root: state.global.root,
	snsFacebook: state.global.snsFacebook,
	snsTwitter: state.global.snsTwitter,
	snsTelegram: state.global.snsTelegram,
	snsLinkedin: state.global.snsLinkedin
})

const mapDispatchToProps = {
}

const ArticleContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Article)


export default ArticleContainer