import React,{Component} from "react"
import {withRouter} from 'react-router-dom'
import { motion, AnimatePresence } from "framer-motion"
import {Container} from 'react-bootstrap'
//import { images } from "../../config/carousel"
import {connect} from 'react-redux'
import axios from 'axios'
import '../../styles/carousel.scss'

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0
    };
  }
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};
const fff=unescape


class Carousel extends Component{
	constructor(props){
		super(props)
		this.state = {
			page: 0,
			direction: 0,
			imageIndex: 0,
			lastInteract: 0,
			pw: 1000,
			interval: null,
			loaded: false,
			canSlide: false,
			destroyed: false,
			pageHeight: 0,
			data:[]
		}
		this.updateSlider = this.updateSlider.bind(this)
		this.onResize = this.onResize.bind(this)
	}
	onResize(){
		this.setState({
			pw: window.innerWidth
		})
	}
	updateInter(){
		this.setState({
			lastInteract: new Date().getTime()
		})
	}
	updateSlider(){
		const newTime = new Date().getTime()
		if(newTime-this.state.lastInteract>3800){
			this.setState({
				lastInteract: newTime
			})
			if(this.state.loaded){
				this.paginate(1)
			}
		}
	}
	componentDidMount(){
		window.addEventListener('resize',this.onResize)
		const that = this
		this.setState({
			lastInteract: new Date().getTime(),
			//interval: setInterval(this.updateSlider,4000),
			pw: window.innerWidth
		})
		axios.get(`${this.props.server}${this.props.apiCarousel}`)
			.then(function (response) {
				//console.log(response.data);
				if(response.data.success&&!that.state.destroyed){
					that.setState({
						loaded: true,
						data: response.data.result.map(d=>{
							return{
								...d,
								width: 0,
								height: 0,
							}
						}),
						lastInteract: new Date().getTime(),
						pw: window.innerWidth,
						canSlide: response.data.result.length>1,
						interval: response.data.result.length>1?setInterval(that.updateSlider,4000):null
						
					})

					response.data.result.map((d,i)=>{
						const img = new Image()
						img.onload = function() {
							console.log(`${i}: loaded: ${d.image}`)
							const w = this.width
							const h = this.height
							that.setState({
								data: that.state.data.map((d2,i2)=>{
									if(i===i2){
										return {
											...d2,
											width: w,
											height: h,
										}
									}
									return d2
								})
							})
							//TODO: update pageHeight if needed
							that.updatePageHeight()
						}
						img.src = d.image
					})
				}else{
					console.log('error loading carousel', response.data.message)
				}
			})
			.catch(function (error) {
				// handle error
				console.log(error);
			})
			.then(function () {
				// always executed
			})
	}
	updatePageHeight(){
		const ww = window.innerWidth
		const w = this.state.data[this.state.imageIndex].width
		const h = this.state.data[this.state.imageIndex].height
		this.setState({
			pageHeight: h * ww/w
		})
	}
	componentWillUnmount(){
		this.setState({ destroyed:true })
		this.state.interval&&clearInterval(this.state.interval)
		window.removeEventListener('resize',this.onResize)
	}
	paginate(newDirection){
		this.setState({
			page: this.state.page + newDirection,
			direction: newDirection,
			//imageIndex: (this.state.page + newDirection + images.length)%images.length
			imageIndex: (Math.ceil(Math.abs((this.state.page + newDirection) / this.state.data.length))*this.state.data.length + this.state.page + newDirection)%this.state.data.length
		})

		this.updatePageHeight()
	}
	gotoPage(p){
		this.setState({
			page: p,
			direction: p>this.state.page ? 1: -1,
			imageIndex: p%this.state.data.length
		})
	}
	renderSlide(){
		//console.log('render one image')
		const data = this.state.data[this.state.imageIndex]
		return(
			<motion.div className="slide-wrapper"
				onTap={()=>{
					const link = fff(data.link)
					window.scrollTo({
						top: 0, 
						behavior: 'smooth'
					})
					//window.open(data.link,"_self")
					window.location.href = link
				}}
			>
				<motion.img className="slide-bg" src={data.image} />
				<Container className="slide-body d-none">
					<div className="slide-body-txt">
						{fff(data.title1)}
					</div>
					<div className="slide-body-txt">
						{fff(data.title2)}
					</div>
					<motion.div className="slide-body-cta"
						whileHover={{scale:1.04}}
						transition={{duration:0.3}}
						whileTap={{opacity:0.9}}
						onTap={()=>{
							const link = fff(data.link)
							if(parseInt(data.type)===2){
								window.scrollTo({
									top: 0, 
									behavior: 'smooth'
								})
								this.props.history.push(`/categories/${link}`)
							}
							else if(parseInt(data.type)===1){
								window.open(link, '_blank').focus();
							}else{
								if(link.indexOf('http')>-1){
									const url = new URL(link)
									//console.log(url)
									if(url.hostname === window.location.hostname){
										this.props.history.push(url.pathname+url.search)
										//this.props.history.push(url.)
									}else{
										window.open(link, '_blank').focus();
									}
								}else{
									window.scrollTo({
										top: 0, 
										behavior: 'smooth'
									})
									this.props.history.push(`/details/${link}`)
								}
							}
						}}
					>
						{fff(data.cta)}
					</motion.div>
				</Container>
			</motion.div>
		)
	}

	render(){
		return(
			<div className="carousel-wrapper section-fluid" style={{height: `${this.state.pageHeight}px`, overflow: 'hidden'}}>
					<AnimatePresence initial={false} custom={this.state.direction}>
						<motion.div
							className="slide"
							key={this.state.page}
							custom={this.state.direction}
							variants={variants}
							initial="enter"
							animate="center"
							exit="exit"
							transition={{
								x: { type: "spring", stiffness: 300, damping: 30 },
								opacity: { duration: 0.2 }
							}}
							drag="x"
							dragConstraints={{ left: 0, right: 0 }}
							dragElastic={1}
							onDragEnd={(e, { offset, velocity }) => {
								if(!this.state.canSlide)return
								const swipe = swipePower(offset.x, velocity.x)
								if (swipe < -swipeConfidenceThreshold) {
									this.updateInter()
									this.paginate(1)
								} else if (swipe > swipeConfidenceThreshold) {
									this.updateInter()
									this.paginate(-1)
								}
							}}
						>
							{
								this.state.loaded && this.renderSlide()
							}
						</motion.div>
					</AnimatePresence>
					<div className="bullets">
						<div className="bullets-bg">
							<svg className="bullets-bg-svg" viewBox={`0 0 ${this.state.pw} 29`} fill="none">
								<path fillRule="evenodd" clipRule="evenodd" d={`M${this.state.pw} 29H0C${568.264/1441*this.state.pw} -8.6877 ${882.406/1441*this.state.pw} -8.88057 ${this.state.pw} 29Z`} fill="white"/>
							</svg>
						</div>
						{
							this.state.data.map((v,k)=>{
								return (
									<motion.div key={`bullet-${k}`} 
										whileHover={{opacity:0.8}}
										whileTap={{opacity:0.8}}
										onTap={
											() => {
												this.updateInter()
												this.gotoPage(k)
											}
										} 
										className={`bullet ${this.state.imageIndex === k ? 'active' : ''}`}
									>
									</motion.div>
								)
							})
						}
					</div>
			</div>
		)
	}
}



const mapStateToProps = state=>({
	server: state.global.server,
	apiCarousel: state.global.apiCarousel
})

const mapDispatchToProps = {
}

const CarouselContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Carousel)

export default withRouter(CarouselContainer)

