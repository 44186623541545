import React,{useEffect, useState} from "react"
// import cFood from '../../assets/category_food.svg'
// import cTravel from '../../assets/category_travel.svg'
// import cShopping from '../../assets/category_shopping.svg'
// import cHotel from '../../assets/category_hotel.svg'
// import cApp from '../../assets/category_app.svg'
// import cLifestyle from '../../assets/category_lifestyle.svg'
// import cHighlight from '../../assets/category_highlight.svg'
import parse from 'html-react-parser'
import {motion} from 'framer-motion'
import { useSelector} from "react-redux"
import axios from "axios"
//const icons = [cFood,cTravel,cShopping,cHotel,cApp,cLifestyle,cHighlight]
const icons = []

const fff = unescape
const trimStart = str => str.replace(new RegExp('^([\\s]*)(.*)$'), '$2')
function ggg(str){
	//console.log('str:',str)
	if(str===null)return ''
	return str.split('\\n').map((v,i)=>{
		let t_str = trimStart(v)
		const hasIndent = t_str.indexOf('&emsp;')>=0
		return <div style={{marginBottom:'20px',marginLeft:hasIndent?'30px':'0px'}} key={`ggg-${i}`}>{t_str.replace('&emsp;','')}</div>
	})
}

function renderDHT(article,index){
	const keyName = ['details','howtos'][index]
	const compareKeyName = ['details_new','howtos_new'][index]
	const classPre = ['offer','howto'][index]
	const keyPre = ['d','h'][index]

	// console.log('-----------')
	// console.log('old:',article[keyName])
	// console.log('new:',article[compareKeyName])

	if(article[compareKeyName]===null || fff(article[compareKeyName])==='<div>null</div>'){
		return (JSON.parse(fff(article[keyName])).filter(f=>f!=='').map((v,k)=>{
			return(
					<div
						className={`article-${classPre}-item p`} key={`${keyPre}-${k}`}
					>
						{ggg(v)}
					</div>
			)
		}))
	}else{
		return (
			<div 
				className={`${compareKeyName}-inner`}
			>
				{parse(fff(article[compareKeyName]))}
			</div>
		)
	}

}

function DealForMap(){
	const pinSelected = useSelector(state=>state.page.pinSelected)
	const dealSelected = useSelector(state=>state.page.deal)
	const icons = useSelector(state=>state.page.icons)

	useEffect(()=>{
		console.log('server******', icons)
	},[])

	useEffect(()=>{
		console.log('dealselected',dealSelected)
	},[dealSelected])

	return(

				<motion.div className="dealInfo" style={{display: pinSelected===-1?'none':'block'}}>
					{dealSelected&&(
						<div className="offer-card-inner">
							<img src={dealSelected.thumb} width="100%" alt="thumbnail"/>
							{dealSelected.category>0 && <img className="category" src={icons.filter(i=>i.id===dealSelected.category)[0].icon} alt="category"></img>}
							<div className="info">
								<div className="vendor">{unescape(dealSelected.vendor)}</div>
								<div className="offer-title">{unescape(dealSelected.title)}</div>
							</div>
							<div className="article-offer-details">
								<div className="article-offer-details-title"><b>Offer Details</b></div>
								<div className="article-offer-details-content">
									{renderDHT(dealSelected,0)}
								</div>
							</div>
							<div className="article-howto">
								<div className="article-howto-title"><b>How to redeem</b></div>
								<div className="article-howto-content">
									{renderDHT(dealSelected,1)}
								</div>
							</div>


							<div className="btn-wrapper">
								<motion.a className="btn-see-deal" 
									whileHover={{opacity:0.6,backgroundColor:'#2b2b2b',color:'white'}}
									href={dealSelected.vanity?`/deals/${dealSelected.vanity}`:`/details/${dealSelected.id}`}
								>
									See deal
								</motion.a>
							</div>
						</div>
					)}
				</motion.div>
	)
}


export default DealForMap