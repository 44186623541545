import { createStore, applyMiddleware,combineReducers} from 'redux'
import thunk from 'redux-thunk'
import page from './reducers/page'


export default function configureStore(initialState) {
    return createStore(
        combineReducers(
			{
				page,
				global: (state={...initialState},action)=>{
					return state
				}
			}
		),
        applyMiddleware(thunk)
    )
}

