import React,{Component} from 'react'
import Carousel from '../components/carousel/Carousel'
import Deals from '../components/deals/Deals'
import {motion} from 'framer-motion'
import {pageVariants} from '../utils/Animate'
import Map from '../components/map/MapOuter'
import Promotion from '../components/promotion/Promotion'
import Footer from '../components/footer/Footer'


class Landing extends Component{
	constructor(props){
		super(props)
		this.gotoAllDeals = this.gotoAllDeals.bind(this)
	}
	gotoAllDeals(){
		this.props.history.push('/deals')
		window.scrollTo({
			top: 0, 
			behavior: 'smooth'
		})
	}
	render(){
		return (
			<motion.div
				variants={pageVariants}
				initial="exit"
				animate="enter"
				exit="exit"
			>
				<div className="landing-page">
					<div className="section">
						<Carousel/>
					</div>
					<div className="section">
						<Deals title="Popular Deals" type="popular" needSlider={true} tag="POPULAR DEAL"/>
					</div>
					<div className="section">
						<Deals title="Latest Deals" type="latest"/>
						<div className="cta-all-deals">
								<motion.div className="btn-all-deals" 
									whileHover={{opacity:0.6,backgroundColor:'#2b2b2b',color:'white'}}
									onTap={this.gotoAllDeals} whileTap={{
										opacity: 0.6,
										backgroundColor: '#2b2b2b',
										color: 'white'
									}}
								>
									See all deals
								</motion.div>
						</div>
					</div>
				</div>
				<Map/>
				<Promotion/>
				<Footer/>
			</motion.div>
		)

	}
}

export default Landing