import React,{Component} from 'react'
import Deals from '../components/deals/Deals'
import {motion} from 'framer-motion'
import {pageVariants} from '../utils/Animate'
import Promotion from '../components/promotion/Promotion'
import {Row,Col} from 'react-bootstrap'
import Footer from '../components/footer/Footer'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {setSearching} from '../redux/actions'
import cSearch from '../assets/search.svg'

// import cFood from '../assets/category_food_2.svg'
// import cTravel from '../assets/category_travel_2.svg'
// import cShopping from '../assets/category_shopping_2.svg'
// import cHotel from '../assets/category_hotel_2.svg'
// import cApp from '../assets/category_app_2.svg'
// import cLifestyle from '../assets/category_lifestyle_2.svg'
// import cHighlight from '../assets/category_highlight_2.svg'
import {DebounceInput} from 'react-debounce-input'

// const searchTypes = [
// 	{type:'Food & Beverage',value:0,icon:cFood},
// 	{type:'Travel',value:1,icon:cTravel},
// 	{type:'Shopping',value:2,icon:cShopping},
// 	{type:'Hotels',value:3,icon:cHotel},
// 	{type:'App Services',value:4,icon:cApp},
// 	{type:'Lifestyle',value:5,icon:cLifestyle},
// 	{type:'Highlights',value:6,icon:cHighlight}
// ]


class AllDeals extends Component{
	constructor(props){
		super(props)
		this.state={
			filters: this.props.icons.map(s=>false),
			useFilter: false,
			words:''
		}
	}
	componentDidMount(){
		console.log(this.props.searching)
		this.props.setSearching(true)
	}
	componentWillUnmount(){
		this.props.setSearching(false)
	}
	toggleFilterT(i){
		let selected = 0 //test if all selected

		const newA = this.state.filters.map((f,fi)=>fi===i?!f:f)
		newA.map(a=>{
			if(a)selected += 1
			return null
		})
		this.setState({
			filters: newA,
			useFilter: selected>0 && selected<this.props.icons.length
		})
	}
	onTextInput(t){
		this.setState({
			words: t.replace(/\s+/g, ' ').replace(/^\s*|\s* $/g , "")
		})
	}
	renderTitle(){
		return(
			<Row className="title-row g-0">
				<h2 className="title">All Deals</h2>
			</Row>
		)
	}
	renderSearch(){
		return(
			<Row className="search-row g-0">
				<Col className="input-wrapper">
					<img src={cSearch}/>
					<DebounceInput
						className="di"
						placeholder="Search by merchant or deals"
						minLength={1}
						debounceTimeout={500}
						onChange={event => (this.onTextInput(event.target.value))}
					/>
				</Col>
			</Row>
		)
	}
	renderCats(){
		return this.props.icons.map((s,i)=>{
			return(
				<motion.div className={`cat-wrapper ${this.state.filters[i]?'active':''}`} key={i} onTap={()=>{
					this.toggleFilterT(i)
				}}>
					<img src={s.icon} width="32"/>
					<div className="info">{s.name}</div>
				</motion.div>
			)
		})
	}
	render(){
		return (
			<motion.div
				variants={pageVariants}
				initial="exit"
				animate="enter"
				exit="exit"
			>
				<div className="deals-page">
					<div className="container search-wrapper g-0">
						{/* {this.renderTitle()} */}
						{this.renderSearch()}
						<Row className="cats-row g-0">
							<div className="inner">
								{this.renderCats()}
							</div>
						</Row>
					</div>
					<Deals title="" type="all" filters={this.state.filters} useFilter={this.state.useFilter} words={this.state.words}/>
				</div>
				<Promotion/>
				<Footer/>
			</motion.div>
		)
	}
}


const mapStateToProps = state=>({
	searching: state.page.searching,
	icons: state.page.icons

})

const mapDispatchToProps = {
	setSearching
}

const AllDealsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AllDeals)


export default withRouter(AllDealsContainer)
