import React,{Component} from 'react'
import {Container} from 'react-bootstrap'
import {LogoWhite as Logo} from '../nav/Logo'
import Twitter from '../../assets/footer-twitter.svg'
import Facebook from '../../assets/footer-fb.svg'
import Linkedin from '../../assets/footer-linkedin.svg'
import Instagram from '../../assets/footer-instagram.svg'
import Poweredby from '../../assets/poweredby.png'
import Iso from '../../assets/iso.png'


class Footer extends Component{
	renderSnsIcons(){
		const links=[
			'https://twitter.com/getinstarem/',
			'https://www.facebook.com/GetInstarem/',
			'https://www.linkedin.com/company/instarem/',
			'https://www.instagram.com/Instaremit/'
		]
		return [Twitter,Facebook,Linkedin,Instagram].map(
			(v,k)=>{
				return(
					<a href={links[k]} target="_blank" rel="noreferrer" className="icon" key={`icon${k}`}>
						<img src={v} alt="sns-icon"/>
					</a>
				)
			}
		)
	}
	renderHeaderLinks(){
		const links = {
			'Send':'https://www.instarem.com/send-money',
			'Business': 'https://www.instarem.com/en-hk/business',
			'About': 'https://www.instarem.com/en-hk/about-us',
			'Reviews': 'https://www.instarem.com/instarem-reviews',
			'Partner Program': 'https://www.instarem.com/partner-program',
			'Press': 'https://www.instarem.com/blog/press-media/',
			'Blog': 'https://www.instarem.com/blog/',
			'Help':'https://www.instarem.com/en-hk/help-and-support'
		}
		return ['Send','Business','About','Reviews','Partner Program','Press','Blog','Help'].map(
			(v,k)=>{
				return(
					<a href={links[v]} target="_blank" rel="noreferrer" className="basic-btn" key={`headerbtn${k}`}>
						{v}
					</a>
				)

			}
		)
	}
	renderLocalLinks(){
		const links=[
			'https://www.instarem.com/en-au/',
			'https://www.instarem.com/en-ca/',
			'https://www.instarem.com/en-eu/',
			'https://www.instarem.com/en-hk/',
			'https://www.instarem.com/en-in/',
			'https://www.instarem.com/en-my/',
			'https://www.instarem.com/en-sg/',
			'https://www.instarem.com/en-gb/',
			'https://www.instarem.com/home',
		]
		return ['Australia','Canada','Europe','Hong Kong','India','Malaysia','Singapore','UK','US'].map(
			(v,k)=>{
				return(
					<a href={links[k]} target="_blank" rel="noreferrer" className={`basic-btn ${k===8?'noafter':''}`} key={`headerbtn${k}`}>
						{v}
					</a>
				)

			}
		)
	}
	renderSitemaps(){
		const links=[
			'https://www.instarem.com/en-au/au-sitemap',
			'https://www.instarem.com/en-ca/ca-sitemap',
			'https://www.instarem.com/en-eu/eu-sitemap',
			'https://www.instarem.com/en-hk/hk-sitemap',
			'https://www.instarem.com/en-in/in-sitemap',
			'https://www.instarem.com/en-my/my-sitemap',
			'https://www.instarem.com/en-sg/sg-sitemap',
			'https://www.instarem.com/en-gb/gb-sitemap',
			'https://www.instarem.com/sitemap',
		]
		return ['AU Sitemap','CA Sitemap','EU Sitemap','HK Sitemap','IN Sitemap','MY Sitemap','SG Sitemap','UK Sitemap','US Sitemap'].map(
			(v,k)=>{
				return(
					<a href={links[k]} target="_blank" rel="noreferrer" className={`basic-btn ${k===8?'noafter':''}`} key={`headerbtn${k}`}>
						{v}
					</a>
				)

			}
		)
	}
	render(){
		return (
			<Container fluid className="footer-wrapper">
				<Container className="content-wrapper">
					<div className="inner">
					<div className="footer-header">
						<div className="footer-header-logo">
							<Logo/>
						</div>
						<div className="footer-header-links d-none d-lg-flex">
							{this.renderHeaderLinks()}
						</div>
					</div>
					<div className="footer-sns">
						<div className="footer-sns-icons">
							<div className="footer-sns-icons-title">Follow Us</div>
							<div className="footer-sns-icons-imgs">
								{this.renderSnsIcons()}
							</div>
						</div>
						<div className="footer-contact">
							<div>Contact Us</div>
							<a href="mailto:support@instarem.com">support@instarem.com</a>
						</div>
						<div className="footer-privacy">
							<a href="https://www.instarem.com/info/all-policies" target="_blank" rel="noreferrer">Private Policy</a>
							<a href="https://www.instarem.com/info/all-policies" target="_blank" rel="noreferrer">Terms & Conditions</a>
						</div>
					</div>
					<div className="footer-nium">
						<div className="footer-nium-lines">
							<div className="footer-nium-line">NIUM Pte. Ltd. (formerly known as Instarem Pte. Limited) is regulated by the Monetary Authority of Singapore as a major payment institution under License No. PS20200276.</div>
							<div className="footer-nium-line">NIUM Pte. Ltd. (formerly known as Instarem Pte. Limited) is the holding company of NIUM and Instarem subsidiaries globally.</div>
						</div>
						<a href="https://www.nium.com/" target="_blank" rel="noreferrer" className="footer-nium-poweredby">
							<img src={Poweredby} alt="nium-logo"/>
						</a>
					</div>
					<div className="footer-localization">
						{this.renderLocalLinks()}
					</div>
					<div className="footer-sitemap">
						{this.renderSitemaps()}
					</div>
					<div className="footer-iso">
						<div>We are ISO27001 Certified</div>
						<img src={Iso} alt="ISO27001"/>
					</div>
					{/* <img src={tmpAssets.footer1} width="100%" className="d-none d-lg-block"/>
					<img src={tmpAssets.footer2} width="100%" className="d-block d-lg-none"/> */}
					</div>
				</Container>
			</Container>
		)
	}
}


export default Footer