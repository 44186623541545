import React,{Component} from 'react'
import {withRouter} from 'react-router-dom'
import {Container} from 'react-bootstrap'
import {LogoBlack as Logo} from './Logo'
import { motion } from 'framer-motion'
import cSearch from '../../assets/search.svg'
import cClose from '../../assets/close.svg'
import {connect} from 'react-redux'
import {setSearching} from '../../redux/actions'


class Nav extends Component{
	constructor(props){
		super(props)
		this.backHome = this.backHome.bind(this)
		this.allDeals = this.allDeals.bind(this)
		this.goBack = this.goBack.bind(this)
	}
	backHome(){
		this.props.history.push('/')
		window.scrollTo({
			top: 0, 
			behavior: 'smooth'
		})
	}
	allDeals(){
		this.props.history.push('/deals')
		window.scrollTo({
			top: 0, 
			behavior: 'smooth'
		})
	}
	goBack(){
		this.props.history.goBack()
		window.scrollTo({
			top: 0, 
			behavior: 'smooth'
		})
	}
	render(){
		return (
			<Container fluid className="nav-wrapper">
				<Container className="logo-wrapper">
					<motion.div whileHover={{opacity:0.8}} whileTap={{opacity:0.6}} onTap={this.backHome}>
						<Logo fill="white"/>
					</motion.div>
					<div className="search">
						<motion.div whileHover={{opacity:0.8}} whileTap={{opacity:0.6}} onTap={this.allDeals} style={{display: this.props.searching?'none':'block'}}>
							<img src={cSearch} alt="search"/>
						</motion.div>
						<motion.div whileHover={{opacity:0.8}} whileTap={{opacity:0.6}} onTap={this.goBack} style={{display: this.props.searching?'block':'none'}}>
							<img src={cClose} height="22" alt="close"/>
						</motion.div>
					</div>
					{/* <div className="debug">{window.innerWidth}:{window.devicePixelRatio}</div> */}
				</Container>
			</Container>
		)
	}
}

const mapStateToProps = state=>({
	searching: state.page.searching
})

const mapDispatchToProps = {
	setSearching
}

const NavContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Nav)

export default withRouter(NavContainer)