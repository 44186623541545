function Logo(fill){
	return (
		<div className="logo-wrapper">
			<svg width="137" height="25" viewBox="0 0 137 25" fill="none">
			<path fillRule="evenodd" clipRule="evenodd" d="M0.130859 23.6623H5.11325V0.0965576H0.130859V23.6623Z" fill={fill || 'black'}/>
			<path fillRule="evenodd" clipRule="evenodd" d="M23.2255 14.3039V23.6628H18.4787V15.1121C18.4787 12.8225 17.233 11.5096 15.2805 11.5096C13.9676 11.5096 12.6546 12.2505 11.9814 13.395V23.6628H7.23438V7.13331H11.9814V9.08579C12.89 7.67175 14.6408 6.79639 16.4925 6.79639C20.6667 6.79639 23.2255 9.6581 23.2255 14.3039Z" fill={fill||'black'}/>
			<path fillRule="evenodd" clipRule="evenodd" d="M25.0078 21.9121L26.4216 18.613C27.9703 19.7575 29.9902 20.3635 32.0102 20.3635C33.4913 20.3635 34.4339 19.7912 34.4339 19.0169C34.4005 16.4921 25.5128 18.3773 25.4456 12.2838C25.4117 9.18658 28.1723 6.79639 32.0775 6.79639C34.4005 6.79639 36.7235 7.36885 38.3731 8.68179L37.0263 12.1157C35.7472 11.1394 33.6597 10.3986 31.8754 10.3986C30.6634 10.3986 29.8556 10.9709 29.8556 11.7115C29.8892 14.1019 38.8441 12.7889 38.9451 18.714C38.9451 21.7774 36.252 23.9993 32.4142 23.9993C29.5865 23.9993 26.9939 23.326 25.0078 21.9121Z" fill={fill||'black'}/>
			<path fillRule="evenodd" clipRule="evenodd" d="M52.211 23.4609C51.605 23.7639 50.6624 23.9322 49.787 23.9322C45.9155 23.9322 43.1885 21.4748 43.1885 17.199V11.6443H40.125V7.13312H43.1885V2.79028H47.9356V7.13312H52.0091V11.6443H47.9356V16.9635C47.9356 18.5119 49.1138 19.4886 50.4939 19.4886C50.9653 19.4886 51.605 19.3873 51.9754 19.2527L52.211 23.4609Z" fill={fill||'black'}/>
			<path fillRule="evenodd" clipRule="evenodd" d="M66.0479 17.4348V13.3949C65.341 12.1829 63.8595 11.2741 62.21 11.2741C59.8871 11.2741 58.1028 13.0246 58.1028 15.3814C58.1028 17.7377 59.8871 19.4882 62.21 19.4882C63.8595 19.4882 65.341 18.6131 66.0479 17.4348ZM70.7947 7.13325V23.6627H66.0479V21.7102C65.2397 23.0571 63.2535 23.9994 61.1661 23.9994C56.8571 23.9994 53.457 20.1618 53.457 15.3814C53.457 10.6008 56.8571 6.79663 61.1661 6.79663C63.22 6.79663 65.2397 7.73926 66.0479 9.08577V7.13325H70.7947Z" fill={fill||'black'}/>
			<path fillRule="evenodd" clipRule="evenodd" d="M83.5171 7.09956L83.2476 11.5435C82.6078 11.3077 81.8001 11.1731 81.1264 11.1731C80.0492 11.1731 78.3322 12.0486 77.5245 13.6641V23.663H72.7773V7.13325H77.5245V9.08577C78.3322 7.67189 80.1162 6.79663 81.4967 6.79663C82.3383 6.79663 83.012 6.89771 83.5171 7.09956Z" fill={fill||'black'}/>
			<path fillRule="evenodd" clipRule="evenodd" d="M95.5717 13.4959C95.37 11.8463 93.8555 10.6343 92.1046 10.6343C90.1181 10.6343 88.8053 11.7117 88.3679 13.4959H95.5717ZM100.184 16.5259H88.3002C88.7383 18.7815 90.2859 19.9595 92.5419 19.9595C94.0233 19.9595 95.5717 19.2863 96.1446 18.2764L99.8473 20.532C98.6354 22.7203 95.8751 23.9996 92.4411 23.9996C87.5934 23.9996 83.7559 20.2289 83.7559 15.3139C83.7559 10.4997 87.5264 6.79639 92.3402 6.79639C96.8175 6.79639 100.319 10.264 100.319 14.6742C100.319 15.2463 100.285 15.8863 100.184 16.5259Z" fill={fill||'black'}/>
			<path fillRule="evenodd" clipRule="evenodd" d="M127.517 13.395V23.663H122.737V14.3713C122.737 12.3851 121.626 11.1731 119.876 11.1731C118.697 11.1731 117.485 11.9136 116.913 12.9236V23.663H112.166V14.3713C112.166 12.3851 110.988 11.1731 109.406 11.1731C108.025 11.1731 106.881 11.9475 106.376 13.0246V23.663H101.629V7.13331H106.376V9.01841C107.15 7.94116 108.732 6.79639 111.089 6.79639C113.176 6.79639 114.691 7.46993 115.701 9.11951C116.711 7.94116 118.562 6.79639 120.751 6.79639C124.689 6.79639 127.517 9.42256 127.517 13.395Z" fill={fill||'black'}/>
			<path fillRule="evenodd" clipRule="evenodd" d="M130.213 20.9694C130.213 19.2862 131.56 17.9733 133.277 17.9733C135.027 17.9733 136.374 19.2862 136.374 20.9694C136.374 22.6529 135.027 23.9995 133.277 23.9995C131.56 23.9995 130.213 22.6529 130.213 20.9694Z" fill={fill||'black'}/>
			</svg>
		</div>

	)
}

export const LogoWhite = ()=>{return Logo('white')}
export const LogoBlack = ()=>{return Logo('black')}

