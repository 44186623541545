import React,{Component, createRef} from 'react'
import {motion} from 'framer-motion'
import amazeCard from '../../assets/amaze-card.png'
import num1 from '../../assets/1.svg'
import num2 from '../../assets/2.svg'
import num3 from '../../assets/3.svg'
import rect1 from '../../assets/rect1.svg'
import rect2 from '../../assets/rect2.svg'
import {Container, Row, Col} from 'react-bootstrap'
import {connect} from 'react-redux'


class Promotion extends Component{
	constructor(props){
		super(props)
		this.cRef = createRef()
		this.onResize = this.onResize.bind(this)
		this.onScroll = this.onScroll.bind(this)
		this.state={
			ot: 1, //cRef.offsetTop
			oh: 10, //cRef.boundingRect.height
			ih: 10000, // window.innerHeight
			progress: 0,
		}
	}
	componentDidMount(){
		setTimeout(()=>{
			this.onResize()
			this.onScroll()
		},400)
		window.addEventListener('resize',this.onResize)
		window.addEventListener('scroll',this.onScroll)
	}
	onResize(){
		if(this.cRef.current){
			this.setState({
				ot: this.cRef.current.offsetTop,
				oh: this.cRef.current.getBoundingClientRect().height,
				ih: window.innerHeight,
			})
		}
	}
	onScroll(){
		//算当前页面底部的活动范围
		const ele = document.documentElement
		const st = ele.scrollTop+this.state.ih
		const sh = ele.scrollHeight
		const start = this.state.ot
		const end = Math.min(this.state.ot + this.state.oh + this.state.ih, sh)
		const progress = Math.max((Math.min(end,st) - start)/(end-start),0)
		this.setState({
			progress: progress,
		})
	}
	componentWillUnmount(){
		window.removeEventListener('resize',this.onResize)
		window.removeEventListener('scroll',this.onScroll)
	}
	render(){
		return (
			<Container fluid className="promotion-wrapper g-0" ref={this.cRef}>
					<div className="bg">
						<motion.img src={rect1} className="rect1"
							style={{
								transform: `translateX(${this.state.progress * 300}px) scale(${this.state.progress*0.5 + 1})`
							}}
						/>
						<motion.img src={rect2} className="rect2"
							style={{
								transform: `translateX(${this.state.progress * -100}px) scale(${this.state.progress*0.2 + 1})`
							}}
						/>
					</div>
					<div className="content">
						<div className="card-wrapper"
							style={{
								transform: `scale(1)`
							}}
						>
							<img src={amazeCard} alt="amaze"/>
						</div>
						<div className="amaze-title">Not using amaze yet?</div>
						<div className="amaze-cta">Sign up and get yours in minutes via the Instarem app.</div>
						<div className="amaze-download">
							<motion.div className="amaze-download-btn"
								whileHover={{opacity:0.8}}
								whileTap={{opacity:0.6}}
								onTap={()=>{
									window.open(this.props.aDownload, '_blank').focus();
								}}
							>
								Download Instarem App
							</motion.div>
						</div>
						<div className="amaze-why">Why use amaze?</div>
						<Row className="amaze-benefits g-0">
							<Col className="amaze-benefit g-0" xs="12" lg="4">
								<div className="amaze-benefit-icon"><img src={num1} alt="1"/></div>
								<div className="amaze-benefit-txt">{this.props.whys[0]}</div>
							</Col>
							<Col className="amaze-benefit g-0" xs="12" lg="4">
								<div className="amaze-benefit-icon"><img src={num2} alt="2"/></div>
								<div className="amaze-benefit-txt">{this.props.whys[1]}</div>
							</Col>
							<Col className="amaze-benefit g-0" xs="12" lg="4">
								<div className="amaze-benefit-icon"><img src={num3} alt="3"/></div>
								<div className="amaze-benefit-txt">{this.props.whys[2]}</div>
							</Col>
						</Row>
					</div>
					{/* <img src={tmpAssets.promotion1} width="100%" className="d-none d-md-block"/>
					<img src={tmpAssets.promotion2} width="100%" className="d-block d-md-none"/> */}
			</Container>
		)
	}
}


const mapStateToProps = state=>({
	whys: state.global.whys,
	aDownload: state.global.aDownload
})

const mapDispatchToProps = {
}

const PromotionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Promotion)



export default PromotionContainer