
import React,{Component} from 'react'
import {motion} from 'framer-motion'
import {pageVariants} from '../utils/Animate'
import Promotion from '../components/promotion/Promotion'
import Footer from '../components/footer/Footer'
import axios from 'axios'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {Container,Row,Col} from 'react-bootstrap'
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu"

// import cFood from '../assets/category_food.svg'
// import cTravel from '../assets/category_travel.svg'
// import cShopping from '../assets/category_shopping.svg'
//const icons = [cFood,cTravel,cShopping]
const icons = []

const fff=unescape

class Categories extends Component{
	constructor(props){
		super(props)
		const _id = this.props.match.params.id
		//console.log('_id',_id,Number.isInteger(parseInt(_id)))
		this.state={
			id: this.props.match.params.id,
			usingVanity: !Number.isInteger(parseInt(_id)),
			destroyed: false,
			cxtMenu: 'cat-cm-'+Math.random()+'-'+Math.random(),
			data: null
		}
	}
	componentWillUnmount(){
		this.setState({
			destroyed:true
		})
	}
	getData(that,url){
		axios.get(url)
			.then(function (response) {
				if(response.data.success&&!that.state.destroyed){
					//console.log('article,success',url,response.data)
					that.setState({
						data: response.data
					})
				}else{
					console.log('read article error, id:',that.state.id)
				}
			})
			.catch(function (error) {
				// handle error
				console.log(error);
			})
			.then(function () {
				// always executed
			});
	}
	componentDidMount(){
		const that = this
		const url = this.state.usingVanity 
		?this.props.server +  `/api/categories/vanity/${this.state.id}`
		:this.props.server +  `/api/categories/${this.state.id}`
		//const url = `/tmp/2.json`
		//console.log('url',url,this.state.id)
		if(this.state.usingVanity){
			axios.get(url)
				.then(function(response){
					if(response.data.success && ! that.state.destroyed){
						console.log('get id from vanity')
						that.getData(that, that.props.server + `/api/categories/${response.data.id}`)
					}else{
						console.log('read category page from vanity error, vanity:',that.state.id)
					}
				}).catch(function(error){
					console.log(error)
				}).then(function(){
				})
		}else{
			this.getData(this,url)
		}
	}
	// gotoAllDeals(){
	// 	this.props.history.push('/deals')
	// 	window.scrollTo({
	// 		top: 0, 
	// 		behavior: 'smooth'
	// 	})
	// }
	renderCategory(cat){
		let img = ''
		const imgs = this.props.icons.filter(i=>i.id===cat)
		if(imgs.length>0){
			img = imgs[0].icon
		}

		return <img className="category" src={img} alt="category"></img>
	}
	renderOfferCardInner(val){
		return(
			<ContextMenuTrigger id={this.state.cxtMenu} attributes={{href:val.vanity?`/deals/${val.vanity}`:`/deals/${val.id}`,func:val.vanity?val.vanity:val.id,vanity:val.vanity}}>
				<motion.div className="offer-card-inner"
					whileTap={{opacity:0.6}}
					whileHover={{opacity:0.8}}
					onTap={()=>{
						this.showDeal(val.vanity)
					}}
				>
					<img src={val.thumb} width="100%" alt="thumbnail"/>
					{this.renderCategory(val.category)}
					<div className="info">
						<div className="vendor">{fff(val.vendor)}</div>
						<div className="offer-title">{fff(val.title)}</div>
					</div>
				</motion.div>
			</ContextMenuTrigger>
		)
	}

	renderDealsWrapper(deals){
		return(
			<Row className="cards g-0">
				{this.renderDeals(deals)}
			</Row>
		)
	}

	renderDeals(data){
		return data.map((v,k)=>{
			return (
				<Col className="offer-card g-0" xs="6" md="4" key={k}>
					{this.renderOfferCardInner(v)}
				</Col>
			)
		})
	}
	showDeal(k){
		//console.log('history',this.props.history)
		this.props.history.push(`/deals/${k}`)
		window.scrollTo({
			top: 0, 
			behavior: 'smooth'
		})
	}
	showDealFromTitle(k){
		//console.log('history',this.props.history)
		this.props.history.push(`/deals/${k}`)
		window.scrollTo({
			top: 0, 
			behavior: 'smooth'
		})
	}
	handleClick = (e, data, target) => {
		console.log('data',data.action)
		if(data.action==='_blank'){
			console.log('blank')
			const href = target.getAttribute('href')
			window.open(href,'_blank')
		}else{
			console.log('self')
			if(target.getAttribute('vanity')){
				this.showDealFromTitle(target.getAttribute('func'))
			}else{
				this.showDeal(target.getAttribute('func'))
			}
		}
    }

	renderDealSections(){
					//<div className="section">
		//<Deals title="Popular Deals" type="popular" needSlider={true} tag="POPULAR DEAL"/>
					//</div>
		const sections = this.state.data.sections
		return sections.map((s,i)=>{
			return <div className="section" key={i}>
				<div className="container deals-wrapper g-0">
					<Row className="title-row g-0">
						<h2 className="title">{fff(s.title)}</h2>
						<h4 className="description">{fff(s.description)}</h4>
					</Row>
					{this.renderDealsWrapper(s.deals)}
				</div>
			</div>
		})
	}
	render(){
		const data = this.state.data
		if(data===null){
			return(<h1>loading</h1>)
		}
		return (
			<motion.div
				variants={pageVariants}
				initial="exit"
				animate="enter"
				exit="exit"
			>
				<div className="landing-page">
					<div className="section">
						<div className="carousel-wrapper section-fluid">
							<div className="slide">
								<div className="slide-wrapper">
									<motion.img className="slide-bg" src={data.pageData.image} />
									<Container className="slide-body-2">
										<div className="slide-body-txt">
											{fff(data.pageData.title)}
										</div>
										<div className="slide-body-txt-sub">
											{fff(data.pageData.description)}
										</div>
									</Container>
								</div>
							</div>
						</div>
					</div>
					{this.renderDealSections()}
					<ContextMenu id={this.state.cxtMenu}>
						<MenuItem data={{action: '_blank'}} onClick={this.handleClick}>
							Open deal in new tab
						</MenuItem>
						<MenuItem data={{action: '_parent'}} onClick={this.handleClick}>
							Open deal in current tab
						</MenuItem>
					</ContextMenu>
				</div>
				<Promotion/>
				<Footer/>
			</motion.div>
		)

	}
}

const mapStateToProps = state=>({
	server: state.global.server,
	apiArticle: state.global.apiArticle,
	icons: state.page.icons
})

const mapDispatchToProps = {
}

const CategoriesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Categories)
export default withRouter(CategoriesContainer)